<template>
<div>
  <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/projectManage'}">项目管理</el-breadcrumb-item>
    <el-breadcrumb-item
      v-for="(item,index) in parentLevel"
      :key="index">
      <span @click="initWithTableId(item.id,index)" class="el-breadcrumb__inner is-link" role="link">{{item.name}}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
  <el-form :inline="true" :model="dataForm" @keyup.enter.native="init()">
    <el-form-item>
      <el-input v-model="dataForm.name" placeholder="具体层级数据名称" clearable @clear="resetSearch()"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="selectByNameAndInit()">查询</el-button>
      <el-button @click="resetSearch()">重置搜素</el-button>
      <el-button @click="resetTable()">显示层级所有信息</el-button>
      <el-button type="primary" @click="addOrUpdateHandle(isLeaf)">新增</el-button>
      <el-button  type="danger" @click="deleteHandle(tableId)" :disabled="dataListSelections.length <= 0">批量删除</el-button>
    </el-form-item>
  </el-form>

  <el-table :data="tableData" border width="80%" @selection-change="selectionChangeHandle">
    <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
    </el-table-column>
    <template v-for="(item,index) in tableCols">
      <el-table-column :prop="item.cols"
                       :label="item.name"
                       :key="index">
      </el-table-column>
    </template>
    <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="200"
        label="操作">
      <template slot-scope="scope">
        <el-button v-if="isLeaf!==0 && scope.row.is_leaf!=='是'" type="text" size="small" @click="initWithParent(tableId, scope.row.id)">查看子层级</el-button>
        <el-button type="text" size="small" @click="addOrUpdateHandle(isLeaf,scope.row.id)" >修改</el-button>
        <el-button type="text" size="small" style="color: red" @click="deleteHandle(tableId,scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
  </el-pagination>

  <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="initWithParent"></add-or-update>
</div>
</template>

<script>
import AddOrUpdate from './universal-table-add-or-update'
export default {
  name: "universal-table",
  data () {
    return {
      testSet:[
        {
          tableId:1,
          tableName:"根层级",
          rowId:2,
        }
      ],
      dataForm: {
        key: '',
        name: '',
      },
      dataListSelections: [],
      tableData: [],
      tableCols: [],
      isLeaf: 0,
      tableId: 0,
      itemId: 0,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      toSonVisible: false,
      parentLevel:[
        {
          name: "cengji1",
          id: 1
        },
        {
          name: "cengji2",
          id: 2
        }
      ],
      enterState: 0,   //进入状态，0代表直接进入，1代表通过层级进入，2代表搜索进入（显示全层级的），4代表搜索进入（显示特定父层级下）
    }
  },
  components: {
    AddOrUpdate,
  },
  watch:{
    //路由监听
    //因为只有从导航栏进入，会有url的变化，因此
    $route(to, from){
      if(to.fullPath!==from.fullPath)
      {
        this.init()
      }
    }
  },

mounted() {
  this.tableId = window.sessionStorage.getItem("tableId")
  this.rowId =window.sessionStorage.getItem("rowId")
  this.enterState=window.sessionStorage.getItem("enterState")
  if(this.enterState==='0')
  {
    this.init();
  }
},
  methods:{
    //显示层级所有数据,重置之后一定显示的是没有parntRowId的状态
    resetTable(){
      this.pageIndex=1
      this.selectEnterDirect()
    },
    //重置搜索，将name制空，page置1，初始化
    resetSearch(){
      this.dataForm.name=''
      this.selectByNameAndInit()
    },
    //不能保证session存储信息正确时，使用该函数初始化
    initWithTableId(id,index)
    {
      this.pageIndex=1
      //首先判断是不是最外层
      if(index===0){
        window.sessionStorage.setItem("enterState",0)
        let rowId=window.sessionStorage.getItem("parentRowId")
        let tableId=window.sessionStorage.getItem("tableId")
        this.$http({
          url: this.$http.adornUrl('/levelrelation/getParentList?tableId='+tableId+'&rowId='+rowId),
          method: 'get',
        }).then(({data})=> {
          this.parentRowList=data.data
          this.tableId=this.parentRowList[0].parentTableId
          window.sessionStorage.setItem("tableId",this.tableId)
          this.init()
        })
      }
      else {
        //没有parent进入时，就没有rowId，直接按没有parent初始化
        if(this.enterState==0){
          window.sessionStorage.setItem("tableId",id)
          this.init()
        }
        //有parent时，根据当前的parentId、parentRowId获取一个list，根据list进行初始化
        else {
          let rowId=window.sessionStorage.getItem("parentRowId")
          let tableId=window.sessionStorage.getItem("tableId")
          this.$http({
            url: this.$http.adornUrl('/levelrelation/getParentList?tableId='+tableId+'&rowId='+rowId),
            method: 'get',
          }).then(({data})=> {
            this.parentRowList=data.data
            console.log(this.parentRowList)
            this.initWithParent(this.parentRowList[index-1].parentTableId,this.parentRowList[index-1].parentRowId)
          })
        }
      }
    },
    //初始化函数，用于直接进入层级，没有父层级信息
    init(){
      this.enterState=0
      window.sessionStorage.setItem("enterState",this.enterState)
      this.tableId = window.sessionStorage.getItem("tableId")
      this.getBreadcrumb(this.tableId)
      this.$http({
        url: this.$http.adornUrl('/level/getById?id='+this.tableId),
        method: 'get',
      }).then(({data}) =>{
        this.parentTableId=data.levelEntity.parentId;
        window.sessionStorage.setItem("parentTableId",this.parentTableId);
        this.$http({
          url: this.$http.adornUrl('/level/isLeaf?id='+this.tableId),
          method: 'get',
        }).then(({data})=>{
          this.isLeaf=data.data;
        }).then(()=>{
          this.$http({
            url: this.$http.adornUrl('/universaltable/getCols?tableId='+this.tableId),
            method: 'get',
          }).then(({data}) => {
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                name: data.data[i].remark,
                cols: data.data[i].name,
              })
            }
          }).then(() => {
            this.selectEnterDirect()
          })
        })
      })
    },

    //初始化函数，用于从父层级进入，携带父层级信息
    initWithParent(tableId,rowId){
      this.enterState=1
      window.sessionStorage.setItem("enterState",this.enterState)
      this.parentRowId=rowId
      this.parentTableId=tableId
      this.$http({
        url: this.$http.adornUrl('/level/getEnabledSon?id='+this.parentTableId),
        method: 'get',
      }).then(({data})=> {
        this.tableId = data.data.id;
      }).then(()=>{
        this.getBreadcrumb(this.tableId)
        window.sessionStorage.setItem("parentRowId",this.parentRowId);
        window.sessionStorage.setItem("parentTableId",this.parentTableId);
        window.sessionStorage.setItem("tableId",this.tableId);
        this.$http({
          url: this.$http.adornUrl('/level/isLeaf?id='+this.tableId),
          method: 'get',
        }).then(({data})=>{
          this.isLeaf=data.data;
        }).then(()=>{
          this.$http({
            url: this.$http.adornUrl('/universaltable/getCols?tableId='+this.tableId),
            method: 'get',
          }).then(({data}) => {
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                name: data.data[i].remark,
                cols: data.data[i].name,
              })
            }
          }).then(() => {
            this.selectWithParent()
          })
        })
      })
    },

    //多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },

    //搜索，同时强制返回第一页
    selectByNameAndInit()
    {
      this.pageIndex=1
      this.selectByName()
    },
    //搜索和初始化应该使用同一个函数，但是搜索时应该有两种状态，即通过父层级进入，有明确的parentRowId，通过页面跳转进入，没有parnetRowId
    //这里以0作为没有parentRowId的状态识别，1作为有parentRowId的状态识别
    selectByName(){
      if(this.enterState===0) {
          this.selectEnterDirect()
      }
      else {
          this.selectWithParent()
      }
    },

    //直接进入页面的搜索方式
    selectEnterDirect() {
      this.enterState=0
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/universaltable/selectByName'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'tableId': this.tableId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },

    //通过父层级进入页面的搜索方式
    selectWithParent(){
      this.enterState=1
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/universaltable/selectByNameWithParent'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'tableId': this.tableId,
          'parentRowId': this.parentRowId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },

    //获取导航栏信息（面包屑，层级）
    getBreadcrumb(id) {
      this.$http({
        url: this.$http.adornUrl('/level/getParentList?id='+this.tableId),
        method: 'get',
      }).then(({data})=> {
        this.parentLevel=data.data
      })
    },

    //添加与修改
    addOrUpdateHandle(leaf,id){
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(leaf,id)
      })
    },

    //删除
    deleteHandle (tableId,id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/universaltable/delete?tableId='+ tableId),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.init()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //每页数量以及翻页函数也应该是由两种构成的，具体采用那种也是由enterState决定
    //由于这个函数一定时进入了页面之后才会触法，因此不需要从session里面取数据
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.enterState===0)
      {
        this.selectEnterDirect()
      }
      else if(this.enterState===1)
      {
        this.selectWithParent()
      }
    },
    // 翻页函数
    currentChangeHandle (val) {
      this.pageIndex = val
      if(this.enterState===0)
      {
        this.selectEnterDirect()
      }
      else if(this.enterState===1)
      {
        this.selectWithParent()
      }
    },


  }
}
</script>

<style scoped>

</style>